<template>
  <div>
    <SortableList ref="sortableList" />

    <b-button @click="add" :style="{ float: 'right' }">add</b-button>
    <b-input v-model="input" />
  </div>
</template>

<script>
import SortableList from './../../index'
import BuefyButton from '@/components/buttons/Buefy'
export default {
  components: {
    SortableList
  },
  data() {
    return {
      input: 111
    }
  },
  methods: {
    add() {
      this.input++

      this.$refs.sortableList.addItem({
        text: ['0', this.input].join(' ')
      })
    }
  },

  mounted() {
    this.$refs.sortableList.start({
      items: [
        [
          {
            string: ['0', ''].join(' '),
            styles: {
              width: '100%'
            }
          },
          {
            component: BuefyButton,
            props: {
              text: 'edit',
              click: function() {}
            }
          }
        ],
        [
          {
            string: ['0', ''].join(' '),
            styles: {
              width: '100%'
            }
          },
          {
            component: BuefyButton,
            props: {
              text: 'edit',
              click: function() {}
            }
          }
        ]
      ]
    })
  }
}
</script>
