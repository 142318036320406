import { calcMovementAreas } from './list/calcMovementAreas'
import { calcItemAreas } from './list/calcItemAreas'
import { setItemAreas } from './list/setItemAreas'

import { itemUpdate } from './list/itemUpdate'

import { firstCalc } from './list/firstCalc'
import { subsequentCalc } from './list/subsequentCalc'
import { move } from './list/move'

import { onSelect } from './list/onSelect'

import _get from 'lodash/get'
import { objKeys } from '@/utilities/Object/keys'

export const methods = {
  _get,
  objKeys,
  async start({
    items,

    //
    //  currently indents don't work
    //
    allowIndents = false
  }) {
    this.isLoading = true

    this.allowIndents = allowIndents

    this.items = items
    this.updateDisplay()

    this.isLoading = false
  },
  addItem(item) {
    this.isLoading = true

    this.items.push(item)

    const index = this.items.length - 1
    this.itemAreas.push({
      added: false,

      actualIndex: index,
      displayIndex: index
    })

    this.updateDisplay()

    this.isLoading = false
  },
  eraseItem({ index: displayIndex }) {
    console.log('erase display index', { displayIndex })

    const item = this.itemAreas.find(item => {
      return item.displayIndex === displayIndex
    })
    item.subtracted = false

    //
    // this is necessary for:
    //  -> updateDisplay via itemsDisplay
    //  -> itemUpdate
    //
    this.items.splice(displayIndex, 1)

    if (this.items.length === 0) {
      this.itemAreas = []
    }

    this.updateDisplay()
  },
  updateDisplay() {
    this.awaitUpdates++
    this.itemsDisplay = this.items.map(item => {
      return item
    })
    this.$forceUpdate()
  },

  calcMovementAreas,
  calcItemAreas,
  setItemAreas,

  move,
  onSelect,

  itemUpdate,
  subsequentCalc,
  firstCalc
}
