import _cloneDeep from 'lodash/cloneDeep'

export function move({ actual, direction = 'up' }) {
  if (this.preventMovement) {
    return
  }
  this.preventMovement = true

  /*
    recalculte item areas & movement areas
  */
  const selectedData = Object.assign(
    {},
    this.itemAreas.find(item => item.actualIndex === actual.selectedIndex)
  )
  const moveData = Object.assign(
    {},
    this.itemAreas.find(item => item.actualIndex === actual.moveIndex)
  )

  const selected = this.itemAreas.find(item => item.actualIndex === actual.selectedIndex)
  const move = this.itemAreas.find(item => item.actualIndex === actual.moveIndex)

  /*
    Make sure flip is with adjacent
    list items, otherwise calculations get
    noisy.
    
    Better than this would probably be to adjust the
    locations of all items in the range.
    
    But with this stop, at least the item locations
    aren't wrong.
    
    This stop is necessary since if the mouse is moving
    fast, a movement area can be skipped over.
  */
  if (Math.abs(move.actualIndex - selected.actualIndex) != 1) {
    console.log('blocked attempt to swap locations of non-adjacent items')
    this.preventMovement = false
    return
  }

  //
  // find by display index
  //
  const moveEl = this.$refs.itemArray[move.displayIndex].$el

  if (direction === 'up') {
    move.y1 = selectedData.y1
    move.y2 = selectedData.y1 + move.height
    move.actualIndex = actual.selectedIndex
    moveEl.style.top = move.y1 + 'px'

    selected.y1 = move.y2
    selected.y2 = move.y2 + selected.height
    selected.actualIndex = actual.moveIndex
  } else {
    move.y1 = selectedData.y2 - move.height
    move.y2 = selectedData.y2
    move.actualIndex = actual.selectedIndex
    moveEl.style.top = move.y1 + 'px'

    selected.y1 = moveData.y1
    selected.y2 = moveData.y1 + selected.height
    selected.actualIndex = actual.moveIndex
  }

  this.restore = {
    y1: selected.y1,
    x1: selected.x1
  }

  this.movementAreas = this.calcMovementAreas({
    itemAreas: _cloneDeep(this.itemAreas)
  })

  this.preventMovement = false
}
