import _cloneDeep from 'lodash/cloneDeep'

export function itemUpdate() {
  if (this.awaitUpdates >= 1) {
    this.awaitUpdates--
  } else {
    //console.log ('no awaitUpdates')
    return
  }

  setTimeout(() => {
    let first = true
    if (Array.isArray(this.itemAreas) && this.itemAreas.length >= 1) {
      first = false
    }

    const { itemAreas, previousItemAreas } = this.calcItemAreas({ first })
    this.itemAreas = itemAreas

    this.movementAreas = this.calcMovementAreas({
      itemAreas: _cloneDeep(itemAreas)
    })
    this.setItemAreas({
      itemAreas,
      previousItemAreas
    })

    this.$forceUpdate()
  }, 0)
}
