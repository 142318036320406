<template>
  <div
    :style="{
      position: 'relative',
      padding: '20px',
      border: '2px solid #ddd'
    }"
  >
    preventMovement {{ preventMovement }}

    <div
      items
      ref="items"
      :style="{
        display: 'table',

        position: 'relative',
        overflowY: 'visible',
        height: '900px',
        width: '100%'
      }"
    >
      <Item
        v-for="(item, index) in itemsDisplay"
        :key="index"
        ref="itemArray"
        :onMod="itemUpdate"
        :onSelect="onSelect"
        :index="index"
        :style="{
          display: 'table-row',
          paddingRight: '100px'
        }"
      >
        <div
          v-for="(column, index2) in item"
          :key="index2"
          :style="{
            display: 'table-cell',
            padding: '0 4px',
            ...(objKeys(_get(column, 'styles', {})).length >= 1 ? column.styles : {})
          }"
        >
          <div v-if="typeof column === 'string'">{{ column }}</div>

          <div v-else-if="typeof _get(column, 'string', null) === 'string'">
            {{ column.string }}
          </div>

          <component
            v-else-if="column && column.component"
            :is="column.component"
            v-bind="column.props"
          ></component>
        </div>

        <div
          :style="{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 0,
            display: 'table-cell',
            width: '100px'
          }"
        >
          <b-button @click="eraseItem({ index })">delete</b-button>
        </div>

        <div
          v-if="guides"
          :style="{
            position: 'absolute',
            background: '#fff',
            right: '100px',
            bottom: '5px',
            width: '500px'
          }"
        >
          itemAreas (actual index) {{ itemAreas[index] }}
        </div>

        <div
          class="indent-indicator"
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            background: 'black'
          }"
        ></div>
      </Item>

      <div
        v-if="guides"
        :style="{
          position: 'absolute',
          heigth: '100%',
          width: '100%',
          top: 0,
          left: 0
        }"
      >
        <div
          v-for="(area, index) in movementAreas"
          :key="index"
          :style="{
            position: 'absolute',
            top: area.y1 + 'px',
            height: area.y2 - area.y1 + 'px',
            background: index === selectedMovementAreaIndex ? '#ddd' : '#444',
            color: '#fff',
            left: 0,
            width: '100px',
            boxSizing: 'border-box',
            border: '3px solid gold',
            opacity: 0.8,
            transition: itemTransition
          }"
        >
          {{ area }}
        </div>
      </div>
    </div>

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
  </div>
</template>

<script>
import Item from './components/Item'

/*
  //
  // the list for displaying the items
  //
  // the indexes don't change,
  // instead the position value indicates
  // where the item is
  //
  var items = [
    {
        y1:
        y2:
        x1:
        
        position: 0
    },
    {
        position: 2
    },
    {
        position: 1
    }
  ]

  var movementAreas = [
    {
        y1:
        y2:
    },
    {},
    {}
  
  ]

  //
  //  the computed order of the items
  //
  varitemsOrder = [ 0, 2, 1 ]

  //
  // computed the trees from
  // flat list??
  //
  var computedItems = [{
    
    
  }]
*/

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  components: {
    Item
  },
  props: {
    guides: {
      type: Boolean,
      default: false
    }
  },
  data,
  methods,
  mounted() {
    addEventListener('resize', () => {
      this.preventMovement = true
      this.isLoading = true

      this.awaitUpdates++
      this.itemUpdate()

      this.preventMovement = false
      this.isLoading = false
    })
  }
}
</script>
