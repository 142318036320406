export function data() {
  return {
    isLoading: true,
    showTransitions: true,

    allowIndent: false,

    itemTransition: 'top .5s, left .5s, width .5s',
    movementTransition: 'width .5s',

    xIndent: 60,
    maxIndent: 4,

    items: [],
    itemsDisplay: [],
    itemAreas: [],
    movementAreas: [],

    awaitUpdates: 0,
    preventMovement: false,

    indexing: false,

    selectedMovementAreaIndex: null,

    selectedDisplayIndex: null,

    mousemove: null
  }
}
