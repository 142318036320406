import _cloneDeep from 'lodash/cloneDeep'

/*
  sorted by actualIndex
*/
function getAttachments({ itemAreas, actualIndex, indent, itemArray }) {
  const itemAreasSorted = itemAreas.sort((a, b) => {
    return a.actualIndex > b.actualIndex
  })

  const attachments = []

  // actual index
  for (let a = actualIndex + 1; a < itemAreasSorted.length; a++) {
    const itemArea = itemAreasSorted[a]
    if (itemArea.indent === indent) {
      break
    }

    itemArea.element = itemArray[itemArea.displayIndex]

    attachments.push(itemArea)
  }

  // console.log ({ itemAreasSorted, actualIndex, indent });
  console.log(attachments)

  return attachments
}

export function onSelect({ event, index: displayIndex, element }) {
  event.preventDefault()
  const component = this

  console.log('onSelect')
  element.style.transition = 'width .3s'

  this.selectedDisplayIndex = displayIndex

  const originalSelectedItemArea = Object.assign(
    {},
    this.itemAreas.find(item => {
      return item.displayIndex === displayIndex
    })
  )
  const actualIndex = originalSelectedItemArea.actualIndex
  this.restore = {
    y1: originalSelectedItemArea.y1,
    x1: originalSelectedItemArea.x1
  }

  //
  //  determine if has attachments
  //
  const attachments = getAttachments({
    itemAreas: _cloneDeep(this.itemAreas),
    actualIndex,
    indent: originalSelectedItemArea.indent,
    itemArray: this.$refs.itemArray
  })

  const itemsContainer = this.$refs.items.getBoundingClientRect()
  const container = {
    x1: parseInt(itemsContainer.left),
    y1: parseInt(itemsContainer.top)
  }

  // locations of the pointer
  // relative to the container
  const pointer = {
    //
    //\____________________________________
    // where the pointer started from
    //  (static)
    sx: event.clientX - container.x1,
    sy: event.clientY - container.y1,
    //
    //\____________________________________
    // where the pointer is now
    //  (dynamic)
    cx: event.clientX - container.x1,
    cy: event.clientY - container.y1,
    //
    //\____________________________________
    // the deltas
    //  i.e. how far the pointer has moved
    dx: 0,
    dy: 0
  }

  function onMouseMove(event) {
    event.preventDefault()

    const currentItemArea = Object.assign(
      {},
      component.itemAreas.find(item => {
        return item.displayIndex === displayIndex
      })
    )
    const actualIndex = currentItemArea.actualIndex

    let previousIndent = null
    if (actualIndex >= 1) {
      previousIndent = component.itemAreas.find(item => {
        return item.actualIndex === actualIndex - 1
      }).indent
    }

    const selectedItemArea = component.itemAreas.find(item => item.actualIndex === actualIndex)

    if (event.type === 'touchmove') {
      const touches = event.changedTouches
      console.log('onMouseMove', {
        event,
        touches: event.changedTouches,
        clientX: touches[0].clientX,
        clientY: touches[0].clientY
      })
    }

    /*
      container location determination
    */
    const itemsContainer = component.$refs.items.getBoundingClientRect()
    const container = {
      x1: parseInt(itemsContainer.left),
      y1: parseInt(itemsContainer.top)
    }

    /*
      pointer location determination
    */
    pointer.cx = event.clientX - container.x1
    pointer.cy = event.clientY - container.y1

    pointer.dx = pointer.cx - pointer.sx
    pointer.dy = pointer.cy - pointer.sy

    /*
      element location determination
    */
    var element_data = {
      y1: pointer.dy + originalSelectedItemArea.y1,
      y2: pointer.dy + originalSelectedItemArea.y2,
      x1: pointer.dx + originalSelectedItemArea.x1
    }
    element.style.top = element_data.y1 + 'px'
    element.style.left = element_data.x1 + 'px'

    if (attachments.length !== 0) {
      let top = element_data.y2
      let left = element_data.x1

      for (let a = 0; a < attachments.length; a++) {
        const attachment = attachments[a]
        const el = attachment.element.$el

        el.style.transition = this.movementTransition
        el.style.top = top + 'px'
        el.style.left = left + attachment.x1 + 'px'

        top += attachment.height

        console.log({ attachment })
      }
    }

    /*
      movement area overlaps are based on:
        
        selected element y1 and y2
        
        selected itemarea y1 and y2
    */
    const movementAreas = component.movementAreas
    //
    const x1 = element_data.x1
    const xIndent = component.xIndent
    let maxIndent = null
    if (typeof previousIndent === 'number') {
      if (actualIndex === 0) {
        maxIndent = 0
      } else if (previousIndent === maxIndent) {
        maxIndent = previousIndent
      } else {
        maxIndent = previousIndent + 1
      }
    }

    if (component.preventMovement) {
      return
    }

    //
    // const indentIndicator = element.querySelectorAll('.indent-indicator')[0]
    //
    for (let a = 0; a < movementAreas.length; a++) {
      const movementArea = movementAreas[a]
      const selectedMovementAreaIndex = a

      if (element_data.y1 < currentItemArea.y1) {
        if (element_data.y1 < movementArea.y2 && element_data.y1 > movementArea.y1) {
          component.selectedMovementAreaIndex = selectedMovementAreaIndex

          if (selectedMovementAreaIndex < actualIndex) {
            component.move({
              direction: 'down',
              actual: {
                selectedIndex: currentItemArea.actualIndex,
                moveIndex: selectedMovementAreaIndex
              }
            })
            break
          }
        }
      } else {
        if (element_data.y2 < movementArea.y2 && element_data.y2 > movementArea.y1) {
          component.selectedMovementAreaIndex = selectedMovementAreaIndex

          if (selectedMovementAreaIndex > actualIndex + 1) {
            component.move({
              direction: 'up',
              actual: {
                selectedIndex: currentItemArea.actualIndex,
                moveIndex: selectedMovementAreaIndex - 1
              }
            })
            break
          }
        }
      }

      if (!component.allowIndent) {
        continue
      }

      /************************************************
        indent determination
      */
      // let indentChange = false
      if (previousIndent === null) {
        element.style.width = `100%`
        component.restore.x1 = 0

        selectedItemArea.x1 = 0
        selectedItemArea.width = `calc(100% - 0px)`
        selectedItemArea.indent = 0
      } else if (typeof previousIndent === 'number') {
        let indent = 0
        for (let b = 0; b <= maxIndent; b++) {
          const min = xIndent * b - 30
          const max = xIndent * (b + 1) - 30

          if ((x1 > min && x1 < max) || (b === maxIndent && x1 > min)) {
            element.style.width = `calc(100% - ${xIndent * b}px)`
            component.restore.x1 = xIndent * b

            selectedItemArea.x1 = xIndent * b
            selectedItemArea.width = `calc(100% - ${xIndent * b}px)`
            selectedItemArea.indent = b

            indent = b
            break
          }
        }
        if (indent === 0) {
          element.style.width = `100%`
          component.restore.x1 = 0

          selectedItemArea.x1 = 0
          selectedItemArea.width = `calc(100% - 0px)`
          selectedItemArea.indent = 0
        }
      }

      //
      //  re-calculate heights, if indent changes...
      //
      setTimeout(() => {
        const height = element.getBoundingClientRect().height

        const originalSelectedItemArea2 = Object.assign(
          {},
          component.itemAreas.find(item => {
            return item.displayIndex === displayIndex
          })
        )

        const itemArea = component.itemAreas.find(item => {
          return item.displayIndex === displayIndex
        })
        itemArea.y2 = originalSelectedItemArea2.y1 + height
        itemArea.height = height

        component.movementAreas = component.calcMovementAreas({
          itemAreas: _cloneDeep(component.itemAreas)
        })
      }, 200)
    }
  }

  this.mousemove = addEventListener('mousemove', onMouseMove)
  this.touchmove = addEventListener('touchmove', onMouseMove)

  function onMouseUp() {
    element.style.top = component.restore.y1 + 'px'
    element.style.left = component.restore.x1 + 'px'
    element.style.transition = component.itemTransition

    component.selectedMovementAreaIndex = null
    component.selectedDisplayIndex = null

    removeEventListener('mousemove', onMouseMove)
    removeEventListener('touchmove', onMouseMove)

    removeEventListener('mouseup', onMouseUp)
    removeEventListener('touchend', onMouseUp)
  }

  addEventListener('mouseup', onMouseUp)
  addEventListener('touchend', onMouseUp)
}
