export function firstCalc({ itemArray }) {
  let top = 0

  const itemAreas = []

  for (let a = 0; a < itemArray.length; a++) {
    const item = itemArray[a]

    const height = item.$el.getBoundingClientRect().height
    itemAreas[a] = {}
    itemAreas[a].y1 = top
    itemAreas[a].y2 = top + height
    itemAreas[a].height = height
    itemAreas[a].x1 = 0
    itemAreas[a].width = `calc(100% - 0px)`
    itemAreas[a].indent = 0
    itemAreas[a].actualIndex = a
    itemAreas[a].displayIndex = a

    top += height
  }

  return {
    itemAreas
  }
}
