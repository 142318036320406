var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"row",style:({
    position: 'absolute',
    background: '#eee',
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 0 1px 1px #000 inset',
    boxSizing: 'border-box'
  }),on:{"mousedown":_vm.select,"touchstart":_vm.select}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }