/*
   
  calculated via: 
    this.itemAreas

  sets data: 
    this.movementAreas
*/

import _cloneDeep from 'lodash/cloneDeep'

export function calcMovementAreas({ itemAreas: _itemAreas }) {
  const unsortedItemAreas = _cloneDeep(_itemAreas)
  if (unsortedItemAreas.length === 0) {
    return
  }

  const itemAreas = unsortedItemAreas.sort((a, b) => {
    return a.actualIndex > b.actualIndex
  })

  let prevMidPoint = parseInt(itemAreas[0].y2 / 2)
  let nextMidPoint = 0

  const movementAreas = []
  movementAreas[0] = {
    y1: 0,
    y2: prevMidPoint
  }

  for (let a = 1; a < itemAreas.length; a++) {
    const area = itemAreas[a]
    const prevArea = itemAreas[a - 1]

    nextMidPoint = area.y2 - parseInt((area.y2 - prevArea.y2) / 2)

    movementAreas[a] = {
      y1: prevMidPoint + 1,
      y2: nextMidPoint
    }

    prevMidPoint = nextMidPoint
  }

  const lastIndex = itemAreas.length - 1

  movementAreas[lastIndex + 1] = {
    y1: prevMidPoint + 1,
    y2: itemAreas[lastIndex].y2
  }

  return movementAreas
}
