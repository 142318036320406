export async function setItemAreas({ itemAreas, previousItemAreas }) {
  const itemArray = this.$refs.itemArray

  let isSubtraction = false
  if (Array.isArray(previousItemAreas) && previousItemAreas.length >= 1) {
    isSubtraction = true
  }

  if (itemArray.length !== itemAreas.length) {
    console.error({
      itemArray,
      itemAreas
    })
    return
  }

  function setTop(item, top) {
    setTimeout(() => {
      item.style.top = top
    }, 0)
  }

  for (let a = 0; a < itemArray.length; a++) {
    const item = itemArray[a].$el
    const itemArea = itemAreas[a]

    const newTop = itemAreas[a].y1 + 'px'

    if (isSubtraction) {
      item.style.transition = ''
      setTop(item, newTop)

      setTimeout(() => {
        item.style.transition = this.itemTransition
      }, 300)
    } else {
      item.style.transition = this.itemTransition
      setTop(item, newTop)
    }

    item.style.width = itemArea.width
    item.style.left = itemArea.x1 + 'px'
  }
}
