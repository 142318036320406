<template>
  <div
    ref="row"
    :style="{
      position: 'absolute',
      background: '#eee',
      padding: '8px',
      borderRadius: '4px',
      boxShadow: '0 0 1px 1px #000 inset',
      boxSizing: 'border-box'
    }"
    @mousedown="select"
    @touchstart="select"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    onMod: Function,
    onSelect: Function,

    // the display index
    index: Number
  },
  mounted() {
    this.onMod()
  },
  updated() {
    this.onMod()
  },
  methods: {
    select(event) {
      this.onSelect({ event, index: this.index, element: this.$refs.row })
    }
  }
}
</script>
