import _cloneDeep from 'lodash/cloneDeep'

/*
  display index changes
  
  actual indexes should remain the same,
  except they need to be adjusted for the modification (erase or add)
*/
const add = function(_itemAreas) {
  let add = null

  for (let a = 0; a < _itemAreas.length; a++) {
    try {
      if (_itemAreas[a].added === false) {
        add = a
      }
    } catch (x) {
      console.error(x)
    }
  }

  return add
}

const subtract = function(_itemAreas) {
  let subtract = null

  for (let a = 0; a < _itemAreas.length; a++) {
    try {
      if (_itemAreas[a].subtracted === false) {
        subtract = a
      }
    } catch (x) {
      console.error(x)
    }
  }

  return subtract
}

export function subsequentCalc({ itemArray, itemAreas: _itemAreas, itemAreasSorted }) {
  if (typeof add(_itemAreas) === 'number') {
    let top = 0
    for (let a = 0; a < _itemAreas.length - 1; a++) {
      top += _itemAreas[a].height
    }

    const addIndex = _itemAreas.length - 1

    console.log({ addIndex, _itemAreas: _cloneDeep(_itemAreas) })

    const item = itemArray[addIndex]
    const height = item.$el.getBoundingClientRect().height

    _itemAreas[addIndex] = {}
    _itemAreas[addIndex].y1 = top
    _itemAreas[addIndex].y2 = top + height
    _itemAreas[addIndex].height = height
    //
    _itemAreas[addIndex].indent = 0
    _itemAreas[addIndex].x1 = 0
    _itemAreas[addIndex].width = `calc(100% - 0px)`
    //
    _itemAreas[addIndex].actualIndex = addIndex
    _itemAreas[addIndex].displayIndex = addIndex

    return {
      itemAreas: _itemAreas
    }
  } else if (typeof subtract(_itemAreas) === 'number') {
    const heightsByDisplayIndex = []
    for (let a = 0; a < itemArray.length; a++) {
      const height = itemArray[a].$el.getBoundingClientRect().height
      heightsByDisplayIndex.push(height)
    }

    console.log({ heightsByDisplayIndex })

    let erasedDisplayIndex = null
    let erasedActualIndex = null
    for (let a = 0; a < _itemAreas.length; a++) {
      const itemArea = _itemAreas[a]

      if (itemArea.subtracted === false) {
        erasedDisplayIndex = itemArea.displayIndex
        erasedActualIndex = itemArea.actualIndex
        _itemAreas.splice(a, 1)
      }
    }

    for (let a = 0; a < _itemAreas.length; a++) {
      const itemArea = _itemAreas[a]

      if (itemArea.displayIndex >= erasedDisplayIndex) {
        itemArea.displayIndex--
      }

      if (itemArea.actualIndex >= erasedActualIndex) {
        itemArea.actualIndex--
      }
    }

    const previousItemAreas = _cloneDeep(_itemAreas)

    let originalIndex = 0
    const sortedItemAreas = _cloneDeep(_itemAreas)
      .map(item => {
        return {
          ...item,
          originalIndex: originalIndex++
        }
      })
      .sort((a, b) => {
        return a.actualIndex > b.actualIndex
      })

    let top = 0
    for (let a = 0; a < sortedItemAreas.length; a++) {
      const itemAreaDisplayIndex = sortedItemAreas[a].displayIndex
      const height = heightsByDisplayIndex[itemAreaDisplayIndex]

      sortedItemAreas[a].height = height
      sortedItemAreas[a].y1 = top
      sortedItemAreas[a].y2 = height + top

      top += height
    }

    console.log({ _itemAreas })

    const modifiedAreas = sortedItemAreas.sort((a, b) => {
      return a.originalIndex > b.originalIndex
    })

    console.log({ modifiedAreas })

    return {
      itemAreas: modifiedAreas,
      previousItemAreas: previousItemAreas
    }
  } else {
    //
    //  as a result of window resize (etc.?)
    //

    const heights = []
    for (let a = 0; a < itemArray.length; a++) {
      const height = itemArray[a].$el.getBoundingClientRect().height
      heights.push(height)
    }

    console.log({ heights })

    let top = 0
    for (let a = 0; a < itemAreasSorted.length; a++) {
      const itemAreaDisplayIndex = itemAreasSorted[a].displayIndex

      const itemArea = _itemAreas.find(itemArea => {
        return itemArea.displayIndex === itemAreaDisplayIndex
      })

      const height = heights[itemAreaDisplayIndex]

      itemArea.y1 = top
      itemArea.y2 = top + height
      itemArea.height = height
      itemArea.x1 = 0

      top += height
    }

    return {
      itemAreas: _itemAreas
    }
  }
}
