var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position: 'relative',
    padding: '20px',
    border: '2px solid #ddd'
  })},[_vm._v(" preventMovement "+_vm._s(_vm.preventMovement)+" "),_c('div',{ref:"items",style:({
      display: 'table',

      position: 'relative',
      overflowY: 'visible',
      height: '900px',
      width: '100%'
    }),attrs:{"items":""}},[_vm._l((_vm.itemsDisplay),function(item,index){return _c('Item',{key:index,ref:"itemArray",refInFor:true,style:({
        display: 'table-row',
        paddingRight: '100px'
      }),attrs:{"onMod":_vm.itemUpdate,"onSelect":_vm.onSelect,"index":index}},[_vm._l((item),function(column,index2){return _c('div',{key:index2,style:(Object.assign({}, {display: 'table-cell',
          padding: '0 4px'},
          (_vm.objKeys(_vm._get(column, 'styles', {})).length >= 1 ? column.styles : {})))},[(typeof column === 'string')?_c('div',[_vm._v(_vm._s(column))]):(typeof _vm._get(column, 'string', null) === 'string')?_c('div',[_vm._v(" "+_vm._s(column.string)+" ")]):(column && column.component)?_c(column.component,_vm._b({tag:"component"},'component',column.props,false)):_vm._e()],1)}),_c('div',{style:({
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 0,
          display: 'table-cell',
          width: '100px'
        })},[_c('b-button',{on:{"click":function($event){return _vm.eraseItem({ index: index })}}},[_vm._v("delete")])],1),(_vm.guides)?_c('div',{style:({
          position: 'absolute',
          background: '#fff',
          right: '100px',
          bottom: '5px',
          width: '500px'
        })},[_vm._v(" itemAreas (actual index) "+_vm._s(_vm.itemAreas[index])+" ")]):_vm._e(),_c('div',{staticClass:"indent-indicator",style:({
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          background: 'black'
        })})],2)}),(_vm.guides)?_c('div',{style:({
        position: 'absolute',
        heigth: '100%',
        width: '100%',
        top: 0,
        left: 0
      })},_vm._l((_vm.movementAreas),function(area,index){return _c('div',{key:index,style:({
          position: 'absolute',
          top: area.y1 + 'px',
          height: area.y2 - area.y1 + 'px',
          background: index === _vm.selectedMovementAreaIndex ? '#ddd' : '#444',
          color: '#fff',
          left: 0,
          width: '100px',
          boxSizing: 'border-box',
          border: '3px solid gold',
          opacity: 0.8,
          transition: _vm.itemTransition
        })},[_vm._v(" "+_vm._s(area)+" ")])}),0):_vm._e()],2),_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }