<template>
  <div :style="{ padding: '0 1in' }">
    <h1>Sortable Lists</h1>

    <A />

    <B />
  </div>
</template>

<script>
import A from './A'
import B from './B'

export default {
  components: {
    A,
    B
  }
}
</script>
