<template>
  <b-button class="button is-info is-light is-small is-outline is-rounded" @click="click">{{
    text
  }}</b-button>
</template>

<script>
/*
  import BuefyButton from '@/components/buttons/Buefy'
  
  <BuefyButton />
  
*/

export default {
  props: {
    click: Function,
    text: String,
    type: {
      type: String,
      default: 'is-primary'
    },
    isRounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
