import _cloneDeep from 'lodash/cloneDeep'

export function calcItemAreas({ first = false } = {}) {
  const itemsContainer = this.$refs.items
  const itemArray = this.$refs.itemArray

  if (first) {
    return this.firstCalc({
      itemsContainer,
      itemArray
    })
  }

  return this.subsequentCalc({
    itemsContainer,
    itemArray,
    itemAreas: _cloneDeep(this.itemAreas),
    itemAreasSorted: _cloneDeep(this.itemAreas).sort((a, b) => {
      return a.actualIndex > b.actualIndex
    })
  })
}
