/*
	// should always return an array of keys

	import { objKeys } from '@/utilities/Object/keys'

	const keys = objKeys (obj);
*/

export function objKeys(obj) {
  let keys = []

  try {
    keys = Object.keys(obj)
  } catch (x) {
    // Object.keys (null) -> error, etc.
  }

  if (Array.isArray(keys)) {
    return keys
  }

  return []
}
